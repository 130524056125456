/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: true, // Change this to true if you want to use the splash screen.
  useCustomCursor: true, // Change this to false if you want the good'ol cursor
  googleTrackingID: "",
};

//Home Page
const greeting = {
  title: "Hola 👋",
  title2: "Daniel Burruchaga",
  logo_name: "buchspro",
  nickname: "danielei9",
  full_name: "Daniel Burruchaga Sola",
  subTitle:
    "Desarrollador Full Stack y desarrollador de firmware y  electrónica, soluciones tecnológicas. Aprendiendo día a día, proyecto tras proyecto.",
  resumeLink:
    "",
  mail: "danielburru@gmail.com",
};

const socialMediaLinks = {
  /* Your Social Media Link */
  github: "https://github.com/danielei9",
  linkedin: "https://www.linkedin.com/in/daniel-burruchaga//",
  gmail: "danielburru@gmail.com",
  gitlab: "",
  facebook: "",
  twitter: "",
  instagram: "https://www.instagram.com/buchspro/",
};

const skills = {
  data: [
    {
      title: "Full Stack Development ",
      fileName: "FullStackImg",
      skills: [
        "⚡ Diseño de Interfaces de usuario web, applicaciones móviles, nextion display... ",
        "⚡ Desarrollo web RESPONSIVE con ReactJS",
        "⚡ Desarrollo de aplicaciones móviles ios/android React Native",
        "⚡ Creating application backend in Node, Express, Flask, Django, TS, sequelize, SQL, mongodb...",
        "⚡ Integración con API de terceros AWS, Firebase..."
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#FFFFFF",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "PHP",
          fontAwesomeClassname: "simple-icons:php",
          style: {
            color: "#7377AD",
          },
        },
        {
          skillName: "ReactJS",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#439743",
          },
        },
        // {
        //   skillName: "GraphQL",
        //   fontAwesomeClassname: "simple-icons:graphql",
        //   style: {
        //     color: "#DE33A6",
        //   },
        // },
        {
          skillName: "Android",
          fontAwesomeClassname: "simple-icons:android",
          style: {
            color: "#3DDC84",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#f89820",
          },
        },
        {
          skillName: "Flutter",
          fontAwesomeClassname: "simple-icons:flutter",
          style: {
            color: "#02569B",
          },
        },
        {
          skillName: "Dart",
          fontAwesomeClassname: "simple-icons:dart",
          style: {
            color: "#29B0EE",
          },
        },
        // {
        //   skillName: "Visual Basic",
        //   fontAwesomeClassname: "simple-icons:dot-net",
        //   style: {
        //     color: "#029FCE",
        //   },
        // },
        {
          skillName: "MySQL",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#4479A1",
          },
        },
        {
          skillName: "jQuery",
          fontAwesomeClassname: "simple-icons:jquery",
          style: {
            color: "#0865A6",
          },
        },
        {
          skillName: "Wordpress",
          fontAwesomeClassname: "simple-icons:wordpress",
          style: {
            color: "#207297",
          },
        },
        {
          skillName: "Apache",
          fontAwesomeClassname: "simple-icons:apache",
          style: {
            color: "#CA1A22",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C",
          fontAwesomeClassname: "simple-icons:c",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "C++",
          fontAwesomeClassname: "simple-icons:cplusplus",
          style: {
            color: "#E94E32",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "simple-icons:postgresql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
      ],
    },
    {
      title: "Desarrollo electrónico y firmware",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Desarrollo de hardware, microcontroladores... Eagle, Kicad, Proteus",
        "⚡ Desarrollo de firmware C, C++",
        "⚡ LTE/4G, Lora , WIfi, Bluettoth...",
        "⚡ Actualización remota OTA",
        "⚡ Prototipado electrónico",
        "⚡ Mantenimiento preventivo Cloud",
      ],
      softwareSkills: [
        /*{
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Netlify",
          fontAwesomeClassname: "simple-icons:netlify",
          style: {
            color: "#38AFBB",
          },
        },
        {
          skillName: "Heroku",
          fontAwesomeClassname: "simple-icons:olo",
          style: {
            color: "#6863A6",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "GitHub Actions",
          fontAwesomeClassname: "simple-icons:githubactions",
          style: {
            color: "#5b77ef",
          },
        },*/
      ],
    },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Técnico en Instalaciones de Telecomunicaciones",
      subtitle: "Grado medio Juan Comenius",
      logo_path: "Comenius.PNG",
      alt_name: "SSGC",
      duration: "2015 - 2017",
      descriptions: [
        "⚡ Montaje y mantenimiento de infraestructuras de telecomunicación ",
        "⚡ Instalaciones de circuito cerrado de televisión y seguridad electrónica,",
        "⚡ Centralitas telefónicas e infraestructuras de redes de voz y datos. ",
        "⚡ Instalaciones de radiocomunicaciones. ",
        "⚡ Sistemas domóticos y equipos informáticos. ",
      ],
      website_link: "https://www.comenius.es/?class=instalaciones-de-telecomunicaciones-2",
    },
    {
      title: "Técnico Superior mantenimiento electrónico",
      subtitle: "Grado Superior Juan Comenius",
      logo_path: "Comenius.PNG",
      alt_name: "SSGC",
      duration: "2017 - 2019",
      descriptions: [
        "⚡ Mantenimiento de equipos de radiocomunicaciones.",
        "⚡Mantenimiento de equipos de voz y datos.",
        "⚡Mantenimiento de equipos de audio.",
        "⚡Mantenimiento de equipos de vídeo.",
      ],
      website_link: "https://www.comenius.es/?class=mantenimiento-electronico-2",
    },
    {
      title: "Tecnologias Interactivas UPV",
      subtitle: "Grado en Ing Tecnologias Interactivas Universidad Politécnica de Valencia",
      logo_path: "UPV.png",
      alt_name: "UPV",
      duration: "2019 - Presente",
      descriptions: [
        "⚡ Diseño y programación Web",
        "⚡ Diseño Electrónico y programación firmware",
        "⚡ IOT / APPS (ios/android/web)",
        "⚡ Inteligencia Artificial y robótica",
        "⚡ Visión Artificial y Realiada Virtual",
        "⚡ Seguridad en redes",
      ],
      website_link: "https://www.upv.es/titulaciones/GTI/indexc.html",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Node,Express, React & MySql full stack web development",
      subtitle: "Udemy",
      logo_path: "react.png",
      certificate_link:
        "https://drive.google.com/file/d/1mxzOFWfg1hQIr_Is7gmJWeyW3BeDgvUe/view?usp=sharing",
      alt_name: "Udemy",
      color_code: "#2AAFED",
      //color_code: "#47A048",
    },
    {
      title: "Curso Completo de bases de datos MongoDB y NoSql",
      subtitle: "Udemy",
      logo_path: "mongo.png",
      certificate_link:
        "https://drive.google.com/file/d/1oLs-bvdgznRlg1qh2ocoDfEmFAsqkgyT/view?usp=sharing",
      alt_name: "Udemy",
      color_code: "#47A048",
      //color_code: "#E2405F",
    },
    {
      title: "Certificado Javascript",
      subtitle: "CodigoFacilito",
      logo_path: "javascript.png",
      certificate_link:
        "https://drive.google.com/file/d/1hJfxEuuj0E1MD62GPtjqSJsG7S4SbFdo/view?usp=sharing",
      alt_name: "CodigoFacilito",
      color_code: "#F6B808",
      //color_code: "#47A048",
    },
    {
      title: "Junior Pentester",
      subtitle: "TryHackMe",
      logo_path: "iit.png",
      certificate_link:
        "",
      alt_name: "TryHackMe",
      color_code: "#2AAFED",
    },
    {
      title: "Postman Student Expert",
      subtitle: "Postman",
      logo_path: "postman.png",
      certificate_link:
        "https://badgr.com/public/assertions/h2qevHs4SlyoHErJQ0mn2g",
      alt_name: "Postman",
      // color_code: "#f36c3d",
      color_code: "#fffbf3",
    },
    // color_code: "#8C151599",
    // color_code: "#7A7A7A",
    // color_code: "#0C9D5899",
    // color_code: "#C5E2EE",
    // color_code: "#ffc475",
    // color_code: "#g",
    // color_code: "#ffbfae",
    // color_code: "#fffbf3",
    // color_code: "#b190b0",
  ],
};

// Experience Page
const experience = {
  title: "Experiencias",
  subtitle: "Trabajos, erasmus, competiciones...",
  description:
    "",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Puestos de Trabajo",
      experiences: [
       
        {
          title: "Prácticas Ogenica i+d",
          company: "Ogenica",
          company_url: "http://Ogenica.com/",
          logo_path: "ogenica.jpg",
          duration: "Marzo 2016 - Junio 2016",
          location: "Burjassot Valencia",
          description:
          `
          Prácticas en Ogenica Ingenieria. Donde realicé un proyecto de estación de trabajo de electrónica: fuente de alimentación,voltimetro, amperímetro, ohmetro... y 
          todo conectado a una App android donde visualizar los datos.
          `,
            // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#FF29AD",
        },
        {
          title: "Técnico Superior",
          company: "Tecnosel",
          company_url: "http://tecnosel.com/",
          logo_path: "tecnosel.png",
          duration: "Marzo 2016 - Sept 2019",
          location: "Chirivella - Valencia",
          description:
          `Encargado de máquina SMT, CNC, horno de placas electrónicas, reparación, programación... LLegué a estar a cargo de la producción en masa de placas electrónicas,
          mediante una máquina SMT pick and place, y de todo el proceso de producción.
          `,
            // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#A80054",
        },
        {
          title: "Scrum Master / Desarrollador",
          company: "Gesinen S.L",
          company_url: "https://gesinen.es/",
          logo_path: "gesinen.jpg",
          duration: "Junio 2021 - Actual",
          location: "Remoto",
          description:
          `Scrum master, organizador de proyectos y desarrollador. Soluciones tecnológicas. Sobre todo trabajamos con protocolos de comunicación Lora / LTE / 4G.
          Firmware y web.
          `,
          color: "#FF29AD",
        },
      ],
    },
    {
      title: "Erasmus",
      experiences: [
        {
          title: "Erasmus + Precision",
          company: "Precision",
          company_url: "",
          logo_path: "Comenius.PNG",
          duration: "Junio 2015 - Sept 2015",
          location: "Lisboa - Portugal",
          description:
          `Se me brindó la ocasión de ir a una gran empresa en Lisboa, Portugal. Precision, donde me encargaba de la electronica de coches.
          `,
          color: "#A80054",
        },
      ],
    },
    {
      title: "Emprendimiento",
      experiences: [
        {
          title: "Proyecto Propio StartUp Ysolve",
          company: "Ysolve",
          company_url: "http://ysolve.es/",
          logo_path: "ysolve.png",
          duration: "abril 2021 - Actual",
          location: "Gandía - Valencia",
          description:
          `Desarrollo de soluciones tecnológicas, hemos realizado máquinas de auto pago, plataforma web, multiplataforma...
          `,
            // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#FF29AD",
        },
        {
          title: "Proyecto Propio StartUp M2U",
          company: "Music2U",
          company_url: "http://music2u.es/",
          logo_path: "music2u.png",
          duration: "junio 2021 - Actual",
          location: "Gandía - Valencia",
          description:
          `Desarrollo de empresa. Se basa en una App de inversion en musica y artistas. Con musica streming, blogs...
          `,
            // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#A80054",
        },
        {
          title: "Concurso Aula emprende",
          company: "UPV",
          company_url: "http://upv.es/",
          logo_path: "UPV.png",
          duration: "octubre 2022",
          location: "Gandía - Valencia",
          description:
          `Participación en concurso aula emprende Valencia UPV.
          `,
            // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#FF29AD",
        },
        {
          title: "Concurso 2k22 Instituto ideas",
          company: "UPV",
          company_url: "http://upv.es/",
          logo_path: "UPV.png",
          duration: "octubre 2022",
          location: "Gandía - Valencia",
          description:
          `Participación en concurso 2k22 Valencia UPV.
          `,
            // "Created Front end of Yearn Financial Mutual Funds website. also degined simple web application for better user experience, designed DB Schemas as well.",
          color: "#A80054",
        },
      ],
    },
    {
      title: "Competiciones",
      experiences: [
        {
          title: "1º Premio FP skills Valencia",
          company: "FP Skills",
          company_url: "",
          logo_path: "valencia.png",
          duration: "octubre 2017 ",
          location: "Valencia",
          description:
            "1º Premio en FP Skills Valencia",
          color: "#FF29AD",
        },
        {
          title: "3º Premio FP skills Comunidad Valenciana",
          company: "FP Skills",
          company_url: "",
          logo_path: "valencia.png",
          duration: "octubre 2017 ",
          location: "Alicante",
          description:
            "3º Premio en FP Skills Comunidad Valenciana",
          color: "#A80054",
        },
        {
          title: "Competición SigueLineas Comunidad Valenciana",
          company: "FP Skills",
          company_url: "",
          logo_path: "valencia.png",
          duration: "noviembre 2017 / 2018 / 2019",
          location: "Alcoi",
          description:
            "Participación en la competición siguelineas ",
          color: "#FF29AD",
        },
        
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Proyectos",
  description:
    "Mis proyectos tienen mucha variedad de tecnologias y herramientas. He realizado desde simples paginas web, app multiplataformas, plataformas web, sensores inteligentes, smart city Componentes, prototipado electrónico...Dejo algunos de mis trabajos aqui...",
  avatar_image_path: "projects_image.svg",
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "Daniel.jpg",
    description:
      "Puedes contactar conmigo desde cualquier método nombrado posteriormente. Trataré de contestarte lo mas rápido posible ;) ",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "No suelo postear mucho pero a veces lo hago, podeis revisar mi trabajo día a día en instagram (@buchspro) o linkedin (Daniel Burruchaga )",
    link: "https://instagram.com/buchspro",
    link2: "https://www.linkedin.com/in/daniel-burruchaga/",
    avatar_image_path: "blogs_image.svg",
  },
};

const projects = {
  data: [
    {
      id: "0",
      name: "Velocista Buchspro",
      url: "https://www.instagram.com/p/B4xkTirI5ek/",
      description: "Robot velocista 100% diseñado, personalizado y programado. uControlador STM32F103. Conectada a una shield y una placa con 10 sensores infrarojos.",
      languages: [
        {
          name: "c",
          iconifyClass: "logos-c",
        },
        {
          name: "3d printer",
          iconifyClass: "mdi:printer-3d-nozzle-alert",
        },
        {
          name: "kicad",
          iconifyClass: "file-icons:kicad",
        },
        {
          name: "kicad",
          iconifyClass: "simple-icons:stmicroelectronics",
        },
      ],
    },
   
    {
      id: "1",
      name: "Pay Machine Controller",
      url: "",
      description:
        "Proyecto privado Empresa UCEMachines S.L.Controlador de Billetero,Moneder,Display,Impresora,TPV",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Raspberry",
          iconifyClass: "logos:raspberry-pi",
        },
      ],
    },
    {
      id: "2",
      name: "TPV-PayMachine",
      url: "",
      description:
        "Proyecto privado Empresa UCEMachines S.L. Software Terminal punto de venta",
      languages: [
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Raspberry",
          iconifyClass: "logos:raspberry-pi",
        },
        
      ],
    },
    {
      id: "3",
      name: "NFT TheClub333",
      url: "https://github.com/danielei9/theclub333",
      description:
        "Proyecto de NFT 333 NFT y web de mint",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
      ],
    },
    {
      id: "4",
      name: "Music2u LandingPage",
      url: "https://music2u.es",
      description:
        "Landing page de proyecto plataforma de inversión en artistas y musica. App propia.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
      ],
    },
    {
      id: "3",
      name: "M2U Front-end",
      url: "",
      description:
        "Front end de plataforma de inversión en artistas y musica React-native. App propia.",
      languages: [
        {
          name: "NodeJS",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "React Native",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      id: "4",
      name: "Music2u Backend",
      url: "",
      description:
        "Backend de plataforma de inversión en artistas y musica. App propia.",
      languages: [
        {
          name: "NodeJs",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "Postgresql",
          iconifyClass: "logos-postgresql",
        },
      ],
    },
    {
      id: "5",
      name: "Parking Count + Display",
      url: "",
      description:
        "Proyecto Privado realizado para la empresa gesinen S.L. Software contador de vehiculos Lora y display.",
      languages: [
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "c",
          iconifyClass: "logos-c",
        },
        {
          name: "ESP32",
          iconifyClass: "mdi:car-esp",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Raspberry",
          iconifyClass: "logos:raspberry-pi",
        },
      ],
    },
    {
      id: "6",
      name: "TFG Hospital la Fé de Valencia",
      url: "https://github.com/danielei9/HospitalLaFe_FrontEnd",
      description:
        "Automatización y procesado de muestras de cancer para trabajo de investigación UPV.",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "Gcode",
          iconifyClass: "vscode-icons:file-type-gcode",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
        {
          name: "Raspberry",
          iconifyClass: "logos:raspberry-pi",
        },

      ],
    },
    {
      id: "7",
      name: "Sensor Calidad de Aire",
      url: "https://github.com/danielei9/environmentalSensor_HW",
      description:
        "Hasta 32 sensores de distintos gases, procesados y enviados mediante LORA o 4G. Prototipo",
      languages: [
        {
          name: "c",
          iconifyClass: "logos-c",
        },
        {
          name: "ESP32",
          iconifyClass: "mdi:car-esp",
        },
       
      ],
    },
    {
      id: "8",
      name: "Captador de Matriculas IA",
      url: "",
      description:
        "Proyecto privado para Gesinen S.L. Captador de matriculas de coches Camara inteligente + raspberry pi + Lora",
      languages: [
        {
          name: "Python",
          iconifyClass: "logos-python",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "NodeJs",
          iconifyClass: "logos-nodejs",
        },
        {
          name: "ESP32",
          iconifyClass: "mdi:car-esp",
        },
        {
          name: "Raspberry",
          iconifyClass: "logos:raspberry-pi",
        },
      ],
    },
    {
      id: "9",
      name: "App Hampo",
      url: "https://github.com/danielei9/Hampo-V2",
      description:
        "App Hampo controladora de jaula autonoma de hamsters.",
      languages: [
        {
          name: "Java",
          iconifyClass: "logos-java",
        },
        {
          name: "Firebase",
          iconifyClass: "logos-firebase",
        },
        {
          name: "Android",
          iconifyClass: "logos-android",
        },
 
      ],
    },
    {
      id: "10",
      name: "Jaula Domótica",
      url: "https://github.com/danielei9/Hampo-V2/tree/main/HARDWARE",
      description: "Hardware de domotización de Jaula de hamster.",
      languages: [
        {
          name: "c",
          iconifyClass: "logos-c",
        },
        {
          name: "ESP32",
          iconifyClass: "mdi:car-esp",
        },
        {
          name: "Raspberry",
          iconifyClass: "logos:raspberry-pi",
        },
      ],
    },
    {
      id: "11",
      name: "Previsualizador de Impuestos",
      url: "https://github.com/danielei9/",
      description: "",
      languages: [
        {
          name: "JavaScript",
          iconifyClass: "logos-javascript",
        },
        {
          name: "HTML5",
          iconifyClass: "vscode-icons:file-type-html",
        },
        {
          name: "CSS3",
          iconifyClass: "vscode-icons:file-type-css",
        },
        {
          name: "React",
          iconifyClass: "logos-react",
        },
      ],
    },
    {
      id: "12",
      name: "Controlador de riego Inteligente",
      url: "",
      description: "Proyecto privado para la empresa Gesinen S.L. Controlador de riego programable Lora.",
      languages: [
        {
          name: "C",
          iconifyClass: "logos-c",
        },
        {
          name: "ESP32",
          iconifyClass: "mdi:car-esp",
        },
      ],
    },
  ],
};

export {
  settings,
  greeting,
  socialMediaLinks,
  skills,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
  projects,
};
